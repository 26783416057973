:root {
  --brand-color: #205393;
  --button-color: #0f6cbd;
  --primary-color: #205393;
  --button-filter: #4b4b4b;
}

.page-wrapper {
  width: 100%;
  margin: auto;
}
.main-content {
  display: flex;
  overflow: hidden;
}
.top-header-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 50px;
  align-items: center;
}
.breadcrumb_main {
  margin: 0px 20px 5px;
}
.content {
  flex: 1;
  width: 100%;
}

.content_area_outlet {
  width: calc(100vw - 200px);
  padding: 0 20px;
}
.content-area {
  width: 100%;
  padding: 0 2px;
}

.page-top-area {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.page-content-area {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 30px;
  background-color: lightgray;
  justify-content: center;
  align-items: center;
}

.asc-button-primary {
  background-color: var(--button-color) !important;
}

/* filter button css */
.filter-button {
  background-color: var(--button-filter) !important;
}

/* Dropdown with datepicker  */
.truncate-dropdown-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* required label */
.required-item {
  color: red;
  font-size: 20px;
}

.react-datepicker__input-container input,
.react-datepicker__input-container input:active {
  width: 100% !important;
  outline: none !important;
  border: none !important;
  padding: 8px !important;
  margin: 3px 0 !important;
  border-radius: 5px;
}
.react-datepicker__triangle {
  position: absolute;
  left: 0px;
  transform: translate(20px, 0px) !important;
}

/* File upload */
.fileupload-container {
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  /* border: 1px dotted black; */
}

.file-upload {
  display: grid;
  justify-content: center;
}

.list-upload-item {
  background-color: white;
  border: 0.5px solid var(--button-color);
  position: relative;
}

.list-upload-item li {
  list-style-type: none;
}

.file-upload-close {
  position: absolute;
  cursor: pointer;
}

.css-mxtnkz-MuiTableContainer-root {
  width: 100%;
  overflow: auto;
  max-width: 100%;
  height: calc(100vh - 350px) !important;
}

.case_detail_text {
  font-weight: bold;
}

.case_detail_text p span {
  font-weight: normal;
}

.button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  outline-style: none;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}
.btn-default {
  background-color: #fff;
  color: #242424;
  border: 1px solid #1d1d1d;
}
.btn-primary {
  background-color: var(--button-color) !important;
  color: #fff;
}
.btn-disabled,
.btn-disabled:hover {
  color: #bdbdbd !important;
  cursor: not-allowed !important;
}
/* .css-1sucic7 .MuiMenu-root .MuiPopover-root .MuiModal-root { */
.MuiPopover-root {
  z-index: 999999999 !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}
.MuiPopper-root {
  z-index: 9999999 !important;
}

.text {
  display: flex;
  gap: 3px;
}

.f1besv5s {
  overflow: auto !important;
}
/* Toggle button css */
.rw4brat:enabled:checked ~ .fui-Switch__indicator,
.rw4brat:enabled:checked:hover ~ .fui-Switch__indicator {
  background-color: green !important;
  color: white !important;
  border-color: white !important;
}
.rw4brat:enabled:not(:checked) ~ .fui-Switch__indicator,
.rw4brat:enabled:not(:checked):hover ~ .fui-Switch__indicator {
  background-color: red !important;
  color: white !important;
  border-color: white !important;
}

.calculation_box {
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0);
  margin: 5px 0;
}
.calculation_main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
}
.calculation_main p {
  font-weight: bold;
  padding-bottom: 5px;
}
.calculation_main p span {
  font-weight: normal;
}
.provider_button {
  background: #fff;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.review_provider_container {
  background-color: rgb(249, 249, 249);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 100%;
}
.review_provider {
  display: grid;
  grid-template-columns: 20% 45%;
}
.case_details {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 0 10px;
}

.payment_compliance {
  /* display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr ; */
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 63%;
}
.payment_compliance_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
}
.payment_compliance_grid_1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
  width: 45%;
}
.payment_notes {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 45%;
}

/* Load standard action */
.loadStandard {
  margin: 5px;
}
.load-standard-main {
  padding: 10px;
}
.load-standard-sub-level p {
  font-weight: bold;
  margin: 5px;
}
.load-action-container {
  display: flex;
  gap: 5px;
  align-items: center;
}
.loadDetail-container {
  border: 0.5px solid rgb(66, 66, 66);
  border-radius: 5px;
  width: 300px;
  padding: 10px;
  margin-top: 5px;
}
.loadDetail-container p {
  font-weight: bold;
  overflow-wrap: break-word;
}
.loadDetail-container span {
  font-weight: normal;
}
.flex-between {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
}
.flex-block {
  display: block;
}
.flex-end {
  display: flex;
  justify-content: end;
  align-items: center;
}
.flex-column {
  display: grid;
  gap: 10px;
}
/* Action list */
.case_action_main {
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 10px 0;
}

.settings-table-content-label {
  font-weight: 500;
}
.actionlist-form-title {
  margin: 10px 0;
}
.acionlist_filter {
  display: flex;
  align-items: center;
  gap: 6px;
}
/* .acionlist_filter .dropdown_button {
  width: 212px !important;
} */
.acionlist_filter .date_dropdown {
  min-width: auto !important;
}
.paddingBottom {
  padding-bottom: 10px;
}

/* .toast_message_main{
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
} */
.toast_titile {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0);
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
}
.actionlist_titile {
  display: flex;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
}
/* Client user Define  */
.user_defined_container {
  background-color: rgb(249, 249, 249);
  padding: 10px;
  margin-top: 50px;
  min-height: 45vh;
}
.user_defined_form {
  grid-gap: 5px 20px;
  display: grid;
  gap: 5px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
}

.user_defined-buttons {
  display: flex;
  gap: 15px;
  padding: 10px;
}
/* Vulnerability */
.form {
  background-color: rgb(249, 249, 249);
  padding: 10px;
  margin-top: 50px;
}
.vulnerability_form {
  background-color: rgb(249, 249, 249);
  padding: 10px;
}
.fieldWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn-wraper-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fui-DialogSurface__backdrop {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.popup_style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.acionlist_filter.badge {
  width: fit-content !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ccc !important;
  border-radius: 50%;
  padding: 8px;
  display: inline-flex;
  align-items: "center";
  justify-content: center;
  font-size: 12px;
}
.customTrigger {
  padding: 4px 8px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}
.dropdown_button {
  width: 215px !important;
}
/* .fui-Tab__content {
  color:#fff !important
}
.f1ksivud::after,
.f1ksivud:hover::before
 {
  background-color:#fff !important;
} */
.fui-BreadcrumbItem {
  padding: 0px !important;
}

/* Apply styles to all scrollbars */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px; /* Also style the horizontal scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}
.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.details .details_label {
  font-weight: 600;
}
.date_picker {
  display: flex;
  gap: 10px;
  align-items: center;
  /* border: 1px solid #ccc; */
}
.content-section,
.client_outlet {
  width: calc(100vw - 245px);
}
.asc_button {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.r1h3qql9 {
  gap: 0 px !important;
}
.case_add {
  display: flex;
  justify-content: flex-end;
}
.actionlist_title {
  display: flex;
  justify-content: space-between;
}
.commission_filter {
  display: flex;
  gap: 10px;
  align-items: center;
}

.commission_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}
.edit-form-content {
  display: flex;
}
.commission_action_main {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 10px 0;
  margin: 10px 0;
}
.dialog_content {
  display: block !important;
  overflow: auto !important;
  padding: 10px;
  height: 500px;
}

.hover-border:hover {
  /* border: 1px solid #cdcdcd !important; */
  border-radius: 4px !important;
}
/* Default table style */
.table_content {
  max-width: 500px;
  table-layout: auto;
  border-collapse: collapse;
  border: 1px solid rgb(230, 224, 224);
}
.table_content th,
.table_content td {
  margin-right: 10px;
  border: 1px solid rgb(230, 224, 224);
}
.filter_style {
  border: 1px solid #e1d8d8;
  box-shadow: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
  border-radius: 5px;
  text-align: center;
  color: #918080;
}
.note_lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case_container_flex {
  height: calc(100vh - 290px);
  overflow-y: auto;
  width: calc(100vw - 230px);
}
.admin_container_flex {
  height: calc(100vh - 225px);
  overflow-y: auto;
  width: calc(100vw - 230px);
}
.case_form_flex {
  /* height: calc(100vh - 460px); */
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  width: calc(100vw - 230px);
}

.ql-editor {
  box-sizing: border-box;
  height: 100%;
  outline: none;
  overflow-y: auto; /* Allow vertical scrolling */
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block !important;
  -webkit-line-clamp: 5 !important; /* Limit to 5 lines */
  -webkit-box-orient: vertical !important;
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  max-width: 100%;
  max-height: calc(5 * 1.42em); /* Adjust based on line height */
}
.ql-editor p:empty {
  display: none;
}
.ql-editor p {
  min-height: 1em; /* Prevents collapse */
}
.small-checkbox .fui-Checkbox__indicator {
  margin: 0 !important;
  padding: 0 !important;
}

.small-checkbox .fui-Checkbox__label {
  margin: 0 0 0 3px !important;
  padding: 0 !important;
}
